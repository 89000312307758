import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import restClient from './axios'
import vuetify from './plugins/vuetify'
import VueCookies from 'vue-cookies'
import SnackBar from './components/SnackBar.vue'
import moment from 'moment'
import { extendMoment } from 'moment-range'
import ToggleButton from 'vue-js-toggle-button'

Vue.use(ToggleButton)

Vue.component('snackbar', SnackBar)

Vue.use(VueCookies)

Vue.prototype.$axios = restClient
Vue.config.productionTip = false

moment.locale('en')
extendMoment(moment)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
