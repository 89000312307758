import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import reservation from './modules/reservation'
import client from '../axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    notification: '',
    serviceTypes: [],
    carTypes: {
      szemelyauto: 'Személyautó',
      varositerepjaro: 'Városi terepjáró',
      nagyterepjaro: 'Nagy terepjáró',
      teherauto: 'Teherautó'
    }
  },
  mutations: {
    setNotification (state, text) {
      state.notification = text
    }
  },
  actions: {
    async getServiceTypes ({ state }) {
      const { data } = await client.get('/serviceTypes')
      state.serviceTypes = data
    }
  },
  getters: {
    getServiceTypeName: (state) => (type) => {
      if (!state.serviceTypes.length) return ''
      return state.serviceTypes.find(({ value }) => value === type).text
    }
  },
  modules: {
    auth,
    reservation
  }
})
