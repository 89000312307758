<template>
  <v-app>
    <v-app-bar color="secondary" app>
      <v-app-bar-nav-icon v-if="$store.state.auth.isLoggedIn" color="white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <h4 class="navigation-title ml-4">{{ $route.meta.title }}</h4>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      app
      width="330px"
    >
      <v-list nav dense>
        <v-list-item-group v-model="group">
          <v-list-item class="brand-name" disabled>
            <div class="autoclean-title my-2"><strong>AutoClean</strong></div>
          </v-list-item>
          <v-divider></v-divider>
          <hr style="height:10px; visibility:hidden;"/>
          <v-list-item :to="{ name: 'reservations' }" exact>
            <v-list-item-title>Foglalások</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'calendar' }" exact>
            <v-list-item-title>Naptár</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'date-manager' }" exact>
            <v-list-item-title>Időpontok kezelése</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn color="quinary" block text @click="logout" class="text-capitalize">
            Kijelentkezés
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
    <snackbar></snackbar>

    <span class="its-invisible">{{ getToggleComputed }}</span>
    <v-footer app padless height="56px" class="the-footer" elevation="7" color="white"
    v-if="$route.name !== 'login'">
      <v-item-group class="v-btn-toggle toggle-for-buttons" mandatory v-model="toggleValue">
        <v-btn depressed elevation="0" class="three-parts px-0" value="reservations"
        color="white" :to="{ name: 'reservations' }" exact>
          <v-icon :color="getColorMainPage()">mdi-list-box-outline</v-icon>
          <span :style="{ color: getColorMainPage() }" class="titles-of-buttons mb-0 mt-1">Foglalások</span>
        </v-btn>
        <v-btn depressed elevation="0" class="three-parts px-0" value="calendar"
        color="white" :to="{ name: 'calendar' }" exact>
          <v-icon :color="getColorCalendar()">mdi-calendar-multiple</v-icon>
          <span :style="{ color: getColorCalendar() }" class="titles-of-buttons mb-0 mt-1">Naptár</span>
        </v-btn>
        <v-btn depressed elevation="0" class="three-parts px-0" value="date-manager"
        color="white" :to="{ name: 'date-manager' }" exact>
          <v-icon :color="getColorDateManager()">mdi-clock-edit-outline</v-icon>
          <span :style="{ color: getColorDateManager() }" class="titles-of-buttons mb-0 mt-1">Időpontok kezelése</span>
        </v-btn>
      </v-item-group>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
    toggleValue: '',
    secondaryColor: '#354ea1'
  }),
  computed: {
    getToggleComputed () {
      this.getToggleValue()
      return ''
    }
  },
  watch: {
    group () {
      this.drawer = false
    }
  },
  methods: {
    async logout () {
      await this.$store.dispatch('auth/logout')
      this.$router.replace({ name: 'login' })
    },
    getToggleValue () {
      if (this.$route.name === 'reservations') {
        this.toggleValue = 'reservations'
      } else if (this.$route.name === 'calendar') {
        this.toggleValue = 'calendar'
      } else if (this.$route.name === 'date-manager') {
        this.toggleValue = 'date-manager'
      }
    },
    getColorMainPage () {
      if (this.$route.name === 'reservations') {
        return this.secondaryColor
      } else {
        return 'black'
      }
    },
    getColorCalendar () {
      if (this.$route.name === 'calendar') {
        return this.secondaryColor
      } else {
        return 'black'
      }
    },
    getColorDateManager () {
      if (this.$route.name === 'date-manager') {
        return this.secondaryColor
      } else {
        return 'black'
      }
    }
  }
}
</script>

<style lang="sass">
.navigation-title
  color: white
.v-navigation-drawer
  .v-list-item
    color: var(--v-secondary-base) !important
    &--active
      background: var(--v-secondary-base)
      color: white !important
.vue2leaflet-map
  z-index: 1
html
  overflow-y: auto !important
.v-navigation-drawer
  .v-list-item__title,.v-btn__content
    font-size: 26px !important
    font-weight: 400 !important
    padding: 10px
  .v-list-item--active
    .v-list-item__title
      color: white
.leaflet-popup-content
  width: 228px !important
  margin: 10px 17px !important
.card-title
  color: white
.v-switch-label
  // font-weight: normal !important
  &.v-left
    margin-left: 12px
    // color: green !important
  &.v-right
    margin-right: 12px
.autoclean-title
  font-size: 24px !important
.brand-name
  justify-content: center !important
.the-footer
  display: grid
  gap: 0px
.three-parts
  width: calc(100% / 3)
  height: 100% !important
.titles-of-buttons
  font-size: 12px
.v-footer
  .v-btn__content
    display: flex
    flex-direction: column
    text-transform: none !important
    letter-spacing: .01em !important
  .toggle-for-buttons
    width: 100% !important
    height: 100% !important
  .theme--light.v-btn--active:hover::before, .theme--light.v-btn--active::before
    opacity: 0 !important
.its-invisible
  display: none !important
</style>
