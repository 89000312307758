import client from '../../axios'

export default {
  state: {
    isLoggedIn: false,
    email: '',
    service: {}
  },
  mutations: {
    setLoggedIn (state, value) {
      state.isLoggedIn = value
    },
    setEmail (state, payload) {
      state.email = payload
    },
    setService (state, payload) {
      state.service = payload
    }
  },
  actions: {
    async checkIfLoggedIn ({ commit, dispatch }) {
      let loggedIn = false
      const response = await client.get('/auth/service-account/me')
      if (response.data === 'No service found') {
        loggedIn = false
      } else {
        commit('setEmail', response.data.email)
        commit('setService', response.data.service)
        loggedIn = true
        dispatch('reservation/getReservations', {}, { root: true })
        dispatch('getServiceTypes', {}, { root: true })
      }

      commit('setLoggedIn', loggedIn)

      return loggedIn
    },
    login (_, payload) {
      return client.post('/auth/service-account/login', payload)
    },
    async logout ({ commit }) {
      await client.post('/auth/logout')
      commit('setLoggedIn', false)
    },
    async addModifiedDate ({ state }, modifiedDate) {
      await client.post('/services/addModifiedDate', modifiedDate)
      state.service.modifiedDates.push(modifiedDate)
    },
    async removeModifiedDate ({ state }, modifiedDate) {
      await client.post('/services/removeModifiedDate', modifiedDate)
      state.service.modifiedDates.splice(state.service.modifiedDates.indexOf(modifiedDate), 1)
    }
  },
  namespaced: true
}
