<template>
  <v-container class="container" fluid>
    <div class="no-reservations" v-if="!reservations.length">
      <div class="label">Nem érkezett még be foglalás.</div>
    </div>
    <div v-else>
      <v-tabs class="mb-4" grow v-model="tab">
        <v-tab>Aktív</v-tab>
        <v-tab>Múltbeli</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-text-field
            class="search-field pt-0"
            v-if="futureReservations.length"
            v-model="search"
            append-icon="mdi-magnify"
            label="Kereső"
            placeholder="Pl.:  Elutasítva, Példa Péter, 14:30, 30 perc"
            single-line
            hide-details
          ></v-text-field>
          <hr v-if="futureReservations.length" style="height:8px; visibility:hidden;"/>
          <v-data-table v-if="futureReservations.length"
            :headers="headers"
            :items="futureReservations"
            :search="search"
            :options.sync="options"
            :loading="loading"
            :footer-props="footerProps"
            item-key="_id"
            class="elevation-1"
            show-expand
            single-expand
            no-results-text="Nincs találat erre a kifejezésre."
          >
                      <!-- eslint-disable-next-line -->
            <template v-slot:item.status="{ item }">
              <div class="status-column">
                <div :style="{ color: getStatusColor(item.status), fontWeight: 500 }">{{ item.status }}</div>
                <v-btn class="ml-2 primary" v-if="item.status !== 'Lemondva'" @click="editStatus(item)" small><v-icon>mdi-pencil-outline</v-icon></v-btn>
              </div>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td class="py-3" :colspan="headers.length">
                <strong class="primary-color mr-2">Email:</strong>{{ item.email }}<br>
                <strong class="primary-color mr-2">Telefonszám:</strong>{{ item.userObject[0].phone === '' ? 'nem adott meg telefonszámot' : item.userObject[0].phone }}<br>
                <hr style="height:8px; visibility:hidden;"/>
                <v-divider></v-divider>
                <hr style="height:8px; visibility:hidden;"/>
                <div v-if="item.userObject[0].addressType === 'personal'">
                  <strong class="primary-color mr-2">Számlázási név:</strong>{{ item.userObject[0].billingName }}
                </div>
                <div v-if="item.userObject[0].addressType === 'company'">
                  <strong class="primary-color mr-2">Cégnév:</strong>{{ item.userObject[0].companyName }}
                </div>
                <div v-if="item.userObject[0].addressType === 'company'">
                  <strong class="primary-color mr-2">Adószám:</strong>{{ item.userObject[0].taxNumber }}
                </div>
                <strong class="primary-color mr-2">Cím:</strong>{{ `${item.userObject[0].zip} ${item.userObject[0].city}, ${item.userObject[0].address}` }}<br>
                <strong class="primary-color mr-2">ÁFÁ-s számlát:</strong>{{ item.userObject[0].afa === true ? 'jelezte, hogy kér' : 'nem kér' }}
                <hr style="height:8px; visibility:hidden;"/>
                <v-divider></v-divider>
                <hr style="height:8px; visibility:hidden;"/>
                <strong class="primary-color">{{ `Autó${item.cars.length > 1 ? 'k' : ''} és szolgáltatások:` }}</strong>
                <div class="content mt-2">
                  <div class="cars-display car mb-3" v-for="car in item.cars" :key="car._id">
                    <v-divider v-if="item.cars.indexOf(car) !== 0" vertical class="mr-2"></v-divider>
                    <div class="ml-2">
                      <strong class="primary-color mr-2">Márka és model:</strong>{{ car.model }}<br>
                      <strong class="primary-color mr-2">Típus:</strong>{{ carTypes[car.type] }}<br>
                      <strong class="primary-color mr-2">Rendszám:</strong>{{ car.plate }}<br>
                      <div class="service-types mt-2 ml-2">
                        <span v-for="(value, service) in car.services" :key="service">
                          <v-chip class="mr-2">{{ getServiceTypeName(service) }}</v-chip>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </template>
          </v-data-table>
          <div class="no-active" v-else>
            Jelenleg nincsenek aktív foglalások.
          </div>
        </v-tab-item>
        <v-tab-item>
          <v-text-field
            class="search-field pt-0"
            v-if="pastReservations.length"
            v-model="search"
            append-icon="mdi-magnify"
            label="Kereső"
            placeholder="Pl.:  Elutasítva, Példa Péter, 14:30, 30 perc"
            single-line
            hide-details
          ></v-text-field>
          <hr v-if="pastReservations.length" style="height:8px; visibility:hidden;"/>
          <v-data-table v-if="pastReservations.length"
            :headers="headersPast"
            :items="pastReservations"
            :search="search"
            :options.sync="optionsPast"
            :loading="loading"
            :footer-props="footerProps"
            item-key="_id"
            class="elevation-1"
            show-expand
            single-expand
            no-results-text="Nincs találat erre a kifejezésre."
          >
                      <!-- eslint-disable-next-line -->
            <template v-slot:item.status="{ item }">
              <div :style="{ color: getStatusColor(item.status), fontWeight: 500 }">{{ item.status }}</div>
            </template>
                                  <!-- eslint-disable-next-line -->
            <template v-slot:item.fulfilled="{ item }">
              <div v-if="item.status === 'Elfogadva'" :style="{ color: getFulfilledColor(item.fulfilled), fontWeight: 500 }">{{ item.fulfilled === true ? 'Igen':'Nem' }}
                <v-btn class="ml-2 primary" v-if="item.status === 'Elfogadva'" @click="editFulfilled(item)" small><v-icon>mdi-pencil-outline</v-icon></v-btn>
              </div>
              <div v-else :style="{ fontWeight: 400, fontSize: '35px' }">-
              </div>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td class="py-3" :colspan="headers.length">
                <strong class="primary-color mr-2">Email:</strong>{{ item.email }}<br>
                <strong class="primary-color mr-2">Telefonszám:</strong>{{ item.userObject[0].phone === '' ? 'nem adott meg telefonszámot' : item.userObject[0].phone }}<br>
                <hr style="height:8px; visibility:hidden;"/>
                <v-divider></v-divider>
                <hr style="height:8px; visibility:hidden;"/>
                <div v-if="item.userObject[0].addressType === 'personal'">
                  <strong class="primary-color mr-2">Számlázási név:</strong>{{ item.userObject[0].billingName }}
                </div>
                <div v-if="item.userObject[0].addressType === 'company'">
                  <strong class="primary-color mr-2">Cégnév:</strong>{{ item.userObject[0].companyName }}
                </div>
                <div v-if="item.userObject[0].addressType === 'company'">
                  <strong class="primary-color mr-2">Adószám:</strong>{{ item.userObject[0].taxNumber }}
                </div>
                <strong class="primary-color mr-2">Cím:</strong>{{ `${item.userObject[0].zip} ${item.userObject[0].city}, ${item.userObject[0].address}` }}<br>
                <strong class="primary-color mr-2">ÁFÁ-s számlát:</strong>{{ item.userObject[0].afa === true ? 'jelezte, hogy kér' : 'nem kér' }}
                <hr style="height:8px; visibility:hidden;"/>
                <v-divider></v-divider>
                <hr style="height:8px; visibility:hidden;"/>
                <strong class="primary-color">{{ `Autó${item.cars.length > 1 ? 'k' : ''} és szolgáltatások:` }}</strong>
                <div class="content mt-2">
                  <div class="cars-display car mb-3" v-for="car in item.cars" :key="car._id">
                    <v-divider v-if="item.cars.indexOf(car) !== 0" vertical class="mr-2"></v-divider>
                    <div class="ml-2">
                      <strong class="primary-color mr-2">Márka és model:</strong>{{ car.model }}<br>
                      <strong class="primary-color mr-2">Típus:</strong>{{ carTypes[car.type] }}<br>
                      <strong class="primary-color mr-2">Rendszám:</strong>{{ car.plate }}<br>
                      <div class="service-types mt-2 ml-2">
                        <span v-for="(value, service) in car.services" :key="service">
                          <v-chip class="mr-2">{{ getServiceTypeName(service) }}</v-chip>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </template>
          </v-data-table>
          <div class="no-active" v-else>
            Nincsenek múltbeli foglalások.
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-dialog v-model="editStatusDialog" width="500">
      <v-card v-if="reservationToEdit">
        <v-card-title class="primary mb-3 card-title">Státusz állítás</v-card-title>
        <v-card-text>
          <div>Aktuális státusz:
            <span :style="{ color: getStatusColor(reservationToEdit.status), fontWeight: 500 }">
              {{ reservationToEdit.status }}
            </span>
          </div>
          <div class="mb-3">Kérjük add meg az új státuszt:</div>
          <toggle-button
            v-model="status"
            :sync="true"
            :width="445"
            :height="50"
            :font-size="20"
            :color="{ checked: 'green', unchecked: 'red' }"
            :labels="{ checked: 'Elfogadás', unchecked: 'Elutasítás' }"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="editStatusDialog = false">Mégse</v-btn>
          <v-btn color="primary" :disabled="isConfirmDisabled" @click="confirmStatus">Rendben</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <reject-reason-dialog :show="rejectReasonDialog" @close="rejectReasonDialog = false" @confirm="setStatus($event)"/>
    <v-dialog v-model="editFulfilledDialog" width="500">
      <v-card v-if="reservationToEdit">
        <v-card-title class="primary mb-3 card-title">Teljesült a szolgáltatás?</v-card-title>
        <v-card-text>
          <div>Aktuális állapot:
            <span :style="{ color: getFulfilledColor(reservationToEdit.fulfilled), fontWeight: 500 }">
              {{ reservationToEdit.fulfilled === true ? 'Igen':'Nem' }}
            </span>
          </div>
          <div class="mb-3">Kérjük add meg az új állapotot:</div>
          <toggle-button
            v-model="status"
            :sync="true"
            :width="445"
            :height="50"
            :font-size="20"
            :color="{ checked: 'green', unchecked: 'red' }"
            :labels="{ checked: 'Igen', unchecked: 'Nem' }"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="editFulfilledDialog = false">Mégse</v-btn>
          <v-btn color="primary" :disabled="isFulfilledConfirmDisabled" @click="setFulfilled">Rendben</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import RejectReasonDialog from '../components/RejectReasonDialog.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import moment from 'moment'
import socket from '../socket'

export default {
  components: {
    RejectReasonDialog
  },
  data: () => ({
    tab: null,
    loading: false,
    editStatusDialog: false,
    editFulfilledDialog: false,
    reservationToEdit: null,
    options: { sortBy: ['fullDate'], sortDesc: [false] },
    optionsPast: { sortBy: ['fullDate'], sortDesc: [true] },
    headers: [
      { text: 'Felhasználónév', value: 'name', sortable: false },
      { text: 'Foglalás pillanata', value: 'reservedAt' },
      { text: 'Időtartam', value: 'duration', sortable: false },
      { text: 'Lefoglalt időpont', value: 'fullDate' },
      { text: 'Státusz', value: 'status' }
    ],
    headersPast: [
      { text: 'Felhasználónév', value: 'name', sortable: false },
      { text: 'Foglalás pillanata', value: 'reservedAt' },
      { text: 'Időtartam', value: 'duration', sortable: false },
      { text: 'Lefoglalt időpont', value: 'fullDate' },
      { text: 'Státusz', value: 'status' },
      { text: 'Szolgáltatás teljesült', value: 'fulfilled', align: 'center' }
    ],
    footerProps: {
      'items-per-page-options': [15, 30, 50, 100, -1],
      'items-per-page-text': 'Sorok száma oldalanként:',
      'items-per-page-all-text': 'Mind'
    },
    search: '',
    rejectReasonDialog: false,
    status: false
  }),
  mounted () {
    socket.on('new-reservation', (serviceId) => {
      if (serviceId === this.service._id) {
        this.getReservations()
        this.setNotification('Új foglalás érkezett!')
      }
    })
    socket.on('modified-reservation', (serviceId) => {
      if (serviceId === this.service._id) {
        this.getReservations()
      }
    })
  },
  computed: {
    ...mapState('reservation', ['reservations', 'cars', 'statuses']),
    ...mapState('auth', ['service']),
    ...mapState(['carTypes']),
    ...mapGetters(['getServiceTypeName']),

    futureReservations () {
      return this.reservations
        .filter(item => moment(item.date).isAfter(moment()))
        .map(reservation => ({ ...reservation, reservedAt: moment(reservation.reservedAt).format('YYYY-MM-DD HH:mm:ss') }))
    },
    pastReservations () {
      return this.reservations
        .filter(item => moment(item.date).isBefore(moment()))
        .map(reservation => ({ ...reservation, reservedAt: moment(reservation.reservedAt).format('YYYY-MM-DD HH:mm:ss') }))
    },
    isConfirmDisabled () {
      if (this.reservationToEdit.status === 'Elfogadva' && this.status) return true
      if (this.reservationToEdit.status === 'Elutasítva' && !this.status) return true
      return false
    },
    isFulfilledConfirmDisabled () {
      if (this.reservationToEdit.fulfilled === true && this.status) return true
      if (this.reservationToEdit.fulfilled === false && !this.status) return true
      return false
    }
  },
  methods: {
    ...mapMutations(['setNotification']),
    ...mapActions('reservation', ['getReservations', 'setReservationStatus', 'setFulfilledStatus']),

    editStatus (reservation) {
      this.reservationToEdit = reservation
      this.editStatusDialog = true
      this.status = reservation.status !== 'Elfogadva'
    },
    editFulfilled (reservation) {
      this.reservationToEdit = reservation
      this.editFulfilledDialog = true
      this.status = reservation.fulfilled !== true
    },
    confirmStatus () {
      if (!this.status) {
        this.rejectReasonDialog = true
      } else {
        this.setStatus()
      }
    },
    setStatus (reason) {
      this.setReservationStatus({ status: this.status ? 'accepted' : 'rejected', reservation: this.reservationToEdit, reason })
      this.editStatusDialog = false
      this.rejectReasonDialog = false
    },
    setFulfilled () {
      this.setFulfilledStatus({ fulfilled: this.status, reservation: this.reservationToEdit })
      this.editFulfilledDialog = false
    },
    getStatusColor (status) {
      switch (status) {
        case 'Függőben':
          return '#edb200'
        case 'Elfogadva':
          return 'green'
        case 'Elutasítva':
          return 'red'
        case 'Lemondva':
          return '#6e0101'
        default:
          return 'black'
      }
    },
    getFulfilledColor (fulfilled) {
      switch (fulfilled) {
        case true:
          return 'green'
        case false:
          return 'red'
        default:
          return 'black'
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.container
  height: 100%
.no-reservations
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 100%
  color: var(--v-primary-base)
.no-active
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 60vh
  color: var(--v-primary-base)
.v-list-item__title
  display: flex
  justify-content: space-between
.reservation-date
  font-size: 16px
.content
  display: flex
  .car
    margin-right: 16px
.primary-color
  color: var(--v-primary-base)
.direction
  margin-top: 10px
.service-name
  font-weight: 500 !important
.status-column
  display: flex
  width: 150px
  justify-content: space-between
.search-field
  width: 50%
  margin-left: auto
  margin-right: 10px
@media only screen and (max-width: 700px)
  .search-field
    width: 100%
    margin-left: auto
    margin-right: 10px
.cars-display
  display: flex
  justify-content: left
  align-items: center
</style>
