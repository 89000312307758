import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Reservations from '../views/Reservations.vue'
import Calendar from '../views/Calendar.vue'
import DateManager from '../views/DateManager.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'reservations',
    component: Reservations,
    meta: {
      title: 'Foglalások'
    }
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: Calendar,
    meta: {
      title: 'Naptár'
    }
  },
  {
    path: '/date-manager',
    name: 'date-manager',
    component: DateManager,
    meta: {
      title: 'Időpontok kezelése'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (
    !store.state.auth.isLoggedIn &&
    to.matched.some(record => !['login', 'register'].includes(record.name))
  ) {
    if (await store.dispatch('auth/checkIfLoggedIn')) {
      next()
    } else {
      next({ name: 'login' })
    }
  } else {
    next()
  }
})

export default router
