<template>
  <v-container class="container" fluid>
    <div class="flex-class pt-3 pb-2">
      <span class="base-buttons">
        <v-btn class="add-button mr-8" color="primary" @click="addDate">
          <v-icon size="25px" left>mdi-plus</v-icon>
          Időpont feloldása
        </v-btn>
        <v-btn class="remove-button" color="primary" @click="removeDate">
          <v-icon size="25px" left>mdi-minus</v-icon>
          Időpont lezárása
        </v-btn>
      </span>
    </div>
    <div>
      <v-text-field
        class="search-field pt-2"
        v-if="service.modifiedDates.length"
        v-model="search"
        append-icon="mdi-magnify"
        label="Kereső"
        placeholder="Keresett kifejezés"
        single-line
        hide-details
      ></v-text-field>
      <hr v-if="service.modifiedDates.length" style="height:8px; visibility:hidden;"/>
      <v-data-table v-if="service.modifiedDates.length"
        :headers="headers"
        :items="service.modifiedDates"
        :search="search"
        :options.sync="options"
        :loading="loading"
        :footer-props="footerProps"
        class="pl-2 elevation-1"
        no-results-text="Nincs találat erre a kifejezésre.">
                      <!-- eslint-disable-next-line -->
        <template v-slot:item.scope="{ item }">
          <div v-if="item.scope === 'Saját foglalás'" class="primary-color" :style="{ fontWeight: 500 }">{{ item.scope }}</div>
          <div v-else>{{ item.scope }}</div>
        </template>
                      <!-- eslint-disable-next-line -->
        <template v-slot:item.whatType="{ item }">
          <div :style="{ color: getTypeColor(item.whatType), fontWeight: 500 }">
            {{ item.whatType === 'add' ? 'Feloldott' : 'Lezárt' }}
          </div>
        </template>
                      <!-- eslint-disable-next-line -->
        <template v-slot:item.note="{ item }">
          <div v-if="item.note">{{ item.note }}</div>
          <div v-else style="text-align: center">-</div>
        </template>
                      <!-- eslint-disable-next-line -->
        <template v-slot:item.actions="{ item }">
          <v-icon color="primary" @click="deleteConfirm(item)">mdi-delete-outline</v-icon>
        </template>
      </v-data-table>
      <div class="no-active" v-else>
        Jelenleg nincsenek kezelt időpontok.
      </div>
    </div>

    <v-dialog v-model="modifyDateDialog" width="600">
      <v-card>
        <v-card-title class="primary mb-3 card-title">
          Lefoglalható időtartam {{ isAdd ? 'feloldása' : 'lezárása' }}
        </v-card-title>
        <v-card-text class="pb-0">
          <div class="primary-color horizontally-middle">
            <div class="info-text mb-4 mt-1">
              <v-icon color="primary" dense left>mdi-information-outline</v-icon>
              Egy alkalommal maximum egy napnyi időtartamot lehet megadni.
            </div>
          </div>
          <div class="flex-class">
            <v-menu ref="pickedDateMenuRef" v-model="pickedDateMenu"
              :close-on-content-click="false" :nudge-right="140" :return-value.sync="pickedDate"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="date-text-field mr-5" :value="pickedDate.replace(/-/g, '.') + '.'"
                  label="Dátum" prepend-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker style="height: 370px" v-model="pickedDate" locale="hu-HU" no-title scrollable :min="currentDate"
              :first-day-of-week="1" width="320" show-adjacent-months color="primary">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="pickedDateMenu = false">Mégse</v-btn>
                <v-btn text color="primary" @click="$refs.pickedDateMenuRef.save(pickedDate)">Kiválaszt</v-btn>
              </v-date-picker>
            </v-menu>
            <v-menu ref="startTimeMenuRef" v-model="startTimeMenu"
              :close-on-content-click="false" :nudge-right="90" :return-value.sync="startTime"
              transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startTime" class="date-text-field mr-5" label="Időtartam kezdete"
                  prepend-icon="mdi-clock-time-four-outline" readonly
                  v-bind="attrs" v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="startTimeMenu" v-model="startTime" :max="endTime"
                format="24hr" color="primary" full-width scrollable
                @click:minute="$refs.startTimeMenuRef.save(startTime)"
                :allowed-minutes="m => m % 15 === 0"
              ></v-time-picker>
            </v-menu>
            <v-menu
              ref="endTimeMenuRef" v-model="endTimeMenu"
              :close-on-content-click="false" :nudge-right="90" :return-value.sync="endTime"
              transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endTime" class="date-text-field" label="Időtartam vége"
                  prepend-icon="mdi-clock-time-four-outline" readonly
                  v-bind="attrs" v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="endTimeMenu" v-model="endTime" :min="startTime"
                format="24hr" color="primary" full-width scrollable
                @click:minute="$refs.endTimeMenuRef.save(endTime)"
                :allowed-minutes="m => m % 15 === 0"
              ></v-time-picker>
            </v-menu>
          </div>
          <div class="flex-class pb-5">
            <v-icon>mdi-menu</v-icon>
            <div class="pt-2 pl-2 pr-5">Típus megadása: </div>
            <v-select class="select-list" v-model="selectedType" :items="selectTypes" solo hide-details></v-select>
          </div>
          <div class="flex-class">
            <v-textarea
              v-model="textareaValue" :rules="textareaRule"
              outlined auto-grow rows="4" row-height="20" counter="200"
              placeholder="Jegyzet írása, további információk megadása (opcionális)"
            ></v-textarea>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="modifyDateDialog = false">Mégse</v-btn>
          <v-btn color="primary" :disabled="isExecuteDisabled" @click="isAdd ? setAddedDate() : setRemovedDate()">Rendben</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" width="400">
      <v-card class="favourites-card">
        <v-card-title class="primary mb-3 card-title">Törlés megerősítése</v-card-title>
        <v-card-text class="pt-2">
          <div>Biztosan törölni szeretnéd?</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancelTheDelete()">Mégse</v-btn>
          <v-btn color="primary" @click="deleteModifiedDate(dateToDelete)">Törlés</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

export default {
  components: {
  },
  data: () => ({
    modifyDateDialog: false,
    deleteDialog: false,
    dateToDelete: {},
    isAdd: false,
    pickedDate: moment().toISOString().substr(0, 10),
    currentDate: moment().toISOString().substr(0, 10),
    pickedDateMenu: false,
    startTime: '8:00',
    startTimeMenu: false,
    endTime: '16:00',
    endTimeMenu: false,
    textareaRule: [v => v.length <= 200 || 'Maximum 200 karakter adható meg.'],
    textareaValue: '',
    selectedType: '',
    selectTypes: [],
    loading: false,
    options: { sortBy: ['date'], sortDesc: [false] },
    headers: [
      { text: 'Kategória', value: 'scope', width: '220px' },
      { text: 'Dátum', value: 'date', width: '145px' },
      { text: 'Mettől', value: 'from', sortable: false, width: '90px' },
      { text: 'Meddig', value: 'to', sortable: false, width: '90px' },
      { text: 'Jelleg', value: 'whatType', width: '145px' },
      { text: 'Jegyzet', value: 'note' },
      { text: '', value: 'actions', sortable: false, width: '70px' }
    ],
    footerProps: {
      'items-per-page-options': [15, 30, 50, 100, -1],
      'items-per-page-text': 'Sorok száma oldalanként:',
      'items-per-page-all-text': 'Mind'
    },
    search: ''
  }),
  mounted () {
  },
  computed: {
    ...mapState('auth', ['service']),

    isExecuteDisabled () {
      if (this.textareaValue.length <= 200) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    ...mapActions('auth', ['addModifiedDate', 'removeModifiedDate']),

    addDate () {
      this.isAdd = true
      this.selectedType = 'Munkanap'
      this.selectTypes = ['Munkanap', 'Ünnep', 'Rendezvény', 'Egyéb']
      this.modifyDateDialog = true
    },
    removeDate () {
      this.isAdd = false
      this.selectedType = 'Saját foglalás'
      this.selectTypes = ['Saját foglalás', 'Ünnep', 'Munkaszüneti nap', 'Szabadság', 'Betegség', 'Technikai ok', 'Rendezvény', 'Áramszünet', 'Felújítás', 'Egyéb']
      this.modifyDateDialog = true
    },
    setAddedDate () {
      const endDateString = this.pickedDate + ' ' + this.endTime
      const thisEndDate = new Date(endDateString)
      const modifiedDate = {
        serviceID: this.service._id,
        whatType: 'add',
        scope: this.selectedType,
        date: this.pickedDate,
        from: this.startTime,
        to: this.endTime,
        note: this.textareaValue,
        endDate: thisEndDate
      }
      this.addModifiedDate(modifiedDate)
      this.modifyDateDialog = false
    },
    setRemovedDate () {
      const endDateString = this.pickedDate + ' ' + this.endTime
      const thisEndDate = new Date(endDateString)
      const modifiedDate = {
        serviceID: this.service._id,
        whatType: 'remove',
        scope: this.selectedType,
        date: this.pickedDate,
        from: this.startTime,
        to: this.endTime,
        note: this.textareaValue,
        endDate: thisEndDate
      }
      this.addModifiedDate(modifiedDate)
      this.modifyDateDialog = false
    },
    deleteConfirm (modifiedDate) {
      this.dateToDelete = modifiedDate
      this.deleteDialog = true
    },
    cancelTheDelete () {
      this.deleteDialog = false
      this.dateToDelete = {}
    },
    deleteModifiedDate (modifiedDate) {
      this.removeModifiedDate(modifiedDate)
      this.deleteDialog = false
      this.dateToDelete = {}
    },
    getTypeColor (type) {
      switch (type) {
        case 'add':
          return 'green'
        case 'remove':
          return 'red'
        default:
          return 'black'
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.container
  height: 100%
.flex-class
  display: flex
.base-buttons
  margin-left: auto
  margin-right: auto
.date-text-field
  width: 160px !important
.start-end-text
  display: inline-flex
  align-items: center
.primary-color
  color: var(--v-primary-base)
.horizontally-middle
  display: flex
  justify-content: center
.info-text
  text-align: center
  font-size: 17px,
.select-list
  max-width: 230px !important
.no-active
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 70vh
  color: var(--v-primary-base)
.search-field
  width: 50%
  margin-left: auto
  margin-right: 10px
@media only screen and (max-width: 700px)
  .search-field
    width: 100%
    margin-left: auto
    margin-right: 10px
</style>
