import moment from 'moment'
import client from '../../axios'

export default {
  state: {
    reservations: [],
    serviceTypes: [],
    statuses: [
      { text: 'Elfogadva', value: 'accepted' },
      { text: 'Elutasítva', value: 'rejected' },
      { text: 'Lemondva', value: 'cancelled' }
    ]
  },
  actions: {
    async getReservations ({ state, rootState }) {
      const { data } = await client.get(`/reservations/service/${rootState.auth.service._id}`)
      state.reservations = data.reservations.map((item) => {
        const cars = []
        for (const carId in item.cars) {
          const car = data.cars.find(car => car._id === carId)
          car.services = item.cars[carId]
          cars.push(car)
        }
        return {
          ...item,
          email: item.userObject[0]?.google?.email || item.userObject[0]?.email,
          name: item.userObject[0]?.google?.name || item.userObject[0]?.name,
          duration: `${item.duration} perc`,
          status: getStatus(item.status),
          date: moment(item.date).format('YYYY-MM-DD HH:mm'),
          fullDate: `${moment(item.date).format('YYYY-MM-DD HH:mm')} - ${moment(item.date).add(parseInt(item.duration, 10), 'minutes').format('HH:mm')}`,
          reservedAt: moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss.SSS'),
          cars,
          fulfilled: item.fulfilled
        }
      })
    },
    async setReservationStatus ({ state }, { reservation, status, reason }) {
      const response = await client.post(`/reservations/${reservation._id}/status`, { status, reason })
      if (response.data.success) {
        const editedReservation = state.reservations.find(({ _id }) => _id === reservation._id)
        editedReservation.status = state.statuses.find(({ value }) => value === status).text
      }
    },
    async setFulfilledStatus ({ state }, { reservation, fulfilled }) {
      const response = await client.post(`/reservations/${reservation._id}/fulfilled`, { fulfilled })
      if (response.data.success) {
        const editedReservation = state.reservations.find(({ _id }) => _id === reservation._id)
        editedReservation.fulfilled = fulfilled
      }
    }
  },
  namespaced: true
}

const getStatus = (status) => {
  switch (status) {
    case 'pending':
      return 'Függőben'
    case 'accepted':
      return 'Elfogadva'
    case 'rejected':
      return 'Elutasítva'
    case 'cancelled':
      return 'Lemondva'
    default:
      return ''
  }
}
