<template>
  <v-container class="login" fluid>
    <h2 class="mb-8">Bejelentkezés</h2>
    <v-form class="login-form" ref="form" v-model="valid" @submit.prevent="submit" lazy-validation>
      <div class="email-input">
        <v-text-field
          :rules="[rules.validEmail]"
          :error-messages="errors.email"
          type="email"
          placeholder="E-mail"
          v-model="email"
          @input="errors.email = null"
          validate-on-blur
          prepend-icon="mdi-account-circle"
        ></v-text-field>
      </div>
      <div class="password-input">
        <v-text-field
          :rules="[rules.passwordRequired]"
          :error-messages="errors.password"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          placeholder="Jelszó"
          v-model="password"
          prepend-icon="mdi-lock"
        ></v-text-field>
      </div>
      <div class="horizontally-middle">
        <v-btn color="quaternary" :loading="loading" class="login-button mx-auto mt-8" type="submit">Folytatás</v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data: () => ({
    email: '',
    password: '',
    showPassword: false,
    loading: false,
    rules: {
      passwordRequired: v => !!v || 'Kérjük add meg a jelszavad.',
      validEmail: v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Kérjük adj meg egy létező e-mail címet.'
    },
    valid: true,
    errors: {}
  }),
  methods: {
    ...mapActions('auth', ['login']),
    async submit () {
      this.errors = {}
      if (!this.$refs.form.validate()) return
      this.loading = true
      const { email, password } = this
      try {
        await this.login({ email, password })
        this.$router.replace({ name: 'reservations' })
      } catch (e) {
        if (e.response?.data?.message === 'wrong_password') {
          this.errors.password = ['Helytelen a megadott jelszó.']
        } else if (e.response?.data?.message === 'no_user_with_email') {
          this.errors.email = ['Nem létezik fiók ezzel az e-mail címmel.']
        }
      }
      this.loading = false
    }
  }
}
</script>

<style lang="sass" scoped>
.login
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 100%
.horizontally-middle
  display: flex
  justify-content: center
.login-button
  width: 80%
  color: white
@media only screen and (max-width: 600px)
  .login-form
    width: 300px
@media only screen and (min-width: 601px)
  .login-form
    width: 350px
</style>
