<template>
  <v-container fluid>
    <div class="calendar-wrapper pb-15">
      <v-sheet height="100%">
        <v-toolbar flat>
          <v-btn class="today-button" color="primary" @click="setToday">Ma</v-btn>
          <v-btn small color="primary" class="prev-next-button" @click="prev">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn small color="primary" class="prev-next-button" @click="next">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title class="ml-2" v-if="$refs.calendar">
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>
        <v-calendar
          ref="calendar"
          v-model="focus"
          :now="today"
          :value="today"
          :events="events"
          color="primary"
          type="week"
          :locale="locale"
          :short-intervals="false"
          :first-interval="firstInterval"
          :interval-minutes="unitInMinutes"
          :interval-count="intervalCount"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          @change="updateRange"
        >
        <!-- Nem működik egyelőre
          <template v-slot:day-body="{ }">
            <div
              class="v-current-time"
              :class="{ first: date === week[0].date }"
              :style="{ top: nowY }"
            ></div>
          </template>
          -->
          <template v-slot:event="{ event }">
            <div class="reserve-label" @click="showReservation(event)">
              <div class="status" :style="{ color: getStatusColor(event.status) }">{{ event.status }}</div>
              <div class="fulfilled" v-if="isItPast(event)"
              :style="{ color: getFulfilledColor(event) }">
                {{ showFulfilled(event) }}
              </div>
              {{ getDuration(event) }}<br>
              <div class="name" v-if="parseInt(event.duration) >= 30">{{ event.name }}</div>
              <template v-if="parseInt(event.duration) >= 45">
                <div v-for="car in event.cars" :key="car._id">
                  <div>{{ car.model }}</div>
                  <div>( {{ car.plate }} )</div>
                </div>
              </template>
            </div>
          </template>
        </v-calendar>
      </v-sheet>
    </div>
    <v-dialog v-model="reservationDialog" width="unset">
      <v-card v-if="selectedReservation">
        <v-card-title class="primary mb-3 card-title">Foglalás adatai</v-card-title>
        <v-card-text class="pb-0">
          <div class="reservation-dialog">
            <strong class="primary-color mr-2">Felhasználónév:</strong>{{ selectedReservation.name }}<br>
            <strong class="primary-color mr-2">Email:</strong>{{ selectedReservation.email }}<br>
            <strong class="primary-color mr-2">Telefon:</strong>{{ selectedReservation.userObject[0].phone === '' ? 'nem adott meg telefonszámot' : selectedReservation.userObject[0].phone }}<br>
            <strong class="primary-color mr-2">Lefoglalt dátum:</strong>{{ getReservationTime(selectedReservation.date, selectedReservation.duration) }}
            <hr style="height:8px; visibility:hidden;"/>
            <v-divider></v-divider>
            <hr style="height:8px; visibility:hidden;"/>
            <div v-if="selectedReservation.userObject[0].addressType === 'personal'">
              <strong class="primary-color mr-2">Számlázási név:</strong>{{ selectedReservation.userObject[0].billingName }}
            </div>
            <div v-if="selectedReservation.userObject[0].addressType === 'company'">
              <strong class="primary-color mr-2">Cégnév:</strong>{{ selectedReservation.userObject[0].companyName }}
            </div>
            <div v-if="selectedReservation.userObject[0].addressType === 'company'">
              <strong class="primary-color mr-2">Adószám:</strong>{{ selectedReservation.userObject[0].taxNumber }}
            </div>
            <strong class="primary-color mr-2">Cím:</strong>{{ `${selectedReservation.userObject[0].zip} ${selectedReservation.userObject[0].city}, ${selectedReservation.userObject[0].address}` }}<br>
            <strong class="primary-color mr-2">ÁFÁ-s számlát:</strong>{{ selectedReservation.userObject[0].afa === true ? 'jelezte, hogy kér' : 'nem kér' }}
            <hr style="height:8px; visibility:hidden;"/>
            <v-divider></v-divider>
            <hr style="height:8px; visibility:hidden;"/>
            <strong class="primary-color">{{ `Autó${selectedReservation.cars.length > 1 ? 'k' : ''} és szolgáltatások:` }}</strong>
            <div class="content">
              <div class="cars-display car mb-3" v-for="car in selectedReservation.cars" :key="car._id">
                <v-divider v-if="selectedReservation.cars.indexOf(car) !== 0" vertical class="mr-2"></v-divider>
                <div class="ml-2">
                  <strong class="primary-color mr-2">Márka és model:</strong>{{ car.model }}<br>
                  <strong class="primary-color mr-2">Típus:</strong>{{ carTypes[car.type] }}<br>
                  <strong class="primary-color mr-2">Rendszám:</strong>{{ car.plate }}<br>
                  <div class="service-types mt-2 ml-2">
                    <span v-for="(value, service) in car.services" :key="service">
                      <v-chip class="mr-2">{{ getServiceTypeName(service) }}</v-chip>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-btn color="primary" @click="showRejectReasonDialog" v-if="isRejectVisible(selectedReservation)">Elutasítás</v-btn>
          <v-btn color="primary" @click="setFulfilled(!selectedReservation.fulfilled)" v-if="isNotFulfilledVisible(selectedReservation)">Nem teljesült</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="setStatus('accepted')" v-if="isAcceptVisible(selectedReservation)">Elfogadás</v-btn>
          <v-btn color="primary" @click="setFulfilled(!selectedReservation.fulfilled)" v-if="isFulfilledVisible(selectedReservation)">Teljesült</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <reject-reason-dialog :show="rejectReasonDialog" @close="rejectReasonDialog = false" @confirm="setStatus('rejected', $event)"/>
  </v-container>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import RejectReasonDialog from '../components/RejectReasonDialog.vue'
import socket from '../socket'

export default {
  components: {
    RejectReasonDialog
  },
  data () {
    const dateFormat = 'YYYY-MM-DD'
    const dateTimeFormat = 'YYYY-MM-DD HH:mm'

    return {
      dateFormat,
      dateTimeFormat,
      locale: 'hu-HU',
      today: moment().format(dateFormat),
      focus: '',
      title: '',
      start: '',
      end: '',
      reservationDialog: false,
      rejectReasonDialog: false,
      selectedReservation: null
    }
  },
  mounted () {
    /* Nem működik még
    this.updateTime()
    */
    socket.on('new-reservation', (serviceId) => {
      if (serviceId === this.service._id) {
        this.getReservations()
        this.setNotification('Új foglalás érkezett!')
      }
    })
    this.$refs.calendar.checkChange()
    this.focus = this.today
  },
  computed: {
    ...mapState('reservation', ['reservations', 'cars', 'statuses']),
    ...mapState('auth', ['service']),
    ...mapState(['carTypes']),
    ...mapGetters(['getServiceTypeName']),

    /*
    cal () {
      return this.ready ? this.$refs.calendar : null
    },
    nowY () {
      return this.cal ? this.$refs.calendar.timeToY(moment().format('HH:mm')) + 'px' : '-10px'
    },
    */
    unit () {
      return this.service.timeUnit
    },
    unitInMinutes () {
      return 60 / this.unit
    },
    events () {
      // itt vizsgáljuk, hogy ha ELUTASITOTT vagy LEMONDOTT, akkor van-e metszete
      // egy ELFOGADOTT-tal, vagy FÜGGŐBEN lévővel, mert akkor nem jelenítjük meg
      const reservations = this.reservations.map((reservation) => ({
        start: moment(reservation.date).format(this.dateTimeFormat),
        end: moment(reservation.date).add(parseInt(reservation.duration, 10), 'minutes').format(this.dateTimeFormat),
        ...reservation
      }))

      return reservations.filter((reservation) => {
        if (!moment(reservation.date).isBetween(this.start.date, this.end.date, undefined, '[]')) {
          return false
        }
        if (reservation.status === 'Elutasítva' || reservation.status === 'Lemondva') {
          const range = moment.range([moment(reservation.start), moment(reservation.end)])
          for (const otherReservation of reservations) {
            if (moment(otherReservation.date).isBetween(this.start.date, this.end.date, undefined, '[]')) {
              const otherRange = moment.range([moment(otherReservation.start), moment(otherReservation.end)])
              const doesIntersect = range.intersect(otherRange)
              if (doesIntersect && (moment(reservation.reservedAt).isBefore(otherReservation.reservedAt))) {
                return false
              }
            }
          }
        }
        return true
      })
    },
    firstInterval () {
      if (!this.events.length) return 8 * this.unit - 1
      const times = this.events.map(({ start }) => moment(start).format('HH:mm').replace(':', ''))
      const minStart = Math.min(...times)
      const startTime = minStart.toString()
      const startHour = startTime.length === 3 ? Number(startTime[0]) : Number(startTime.substr(0, 2))
      return startHour * this.unit - 1
    },
    intervalCount () {
      if (!this.events.length) return 8
      const times = this.events.map(({ end }) => moment(end).format('HH:mm').replace(':', ''))
      const maxStart = Math.max(...times)
      const endTime = maxStart.toString()
      const endHour = endTime.length === 3 ? Number(endTime[0]) : Number(endTime.substr(0, 2))
      return endHour * this.unit - this.firstInterval + this.unit + 1
    }
  },
  methods: {
    ...mapMutations(['setNotification', 'setSelectedService']),
    ...mapActions('reservation', ['getReservations', 'setReservationStatus', 'setFulfilledStatus']),

    /* Még nem működik
    getCurrentTime () {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime () {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)

      this.cal.scrollToTime(first)
    },
    updateTime () {
      setInterval(() => this.$refs.calendar.updateTimes(), 60 * 1000)
    },
    */
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    updateRange ({ start, end }) {
      this.start = start
      this.end = end

      const yr = this.start.year
      const startMonth = this.start.month
      const endMonth = this.end.month

      const mNames = [
        'Január',
        'Február',
        'Március',
        'Április',
        'Május',
        'Június',
        'Július',
        'Augusztus',
        'Szeptember',
        'Október',
        'November',
        'December'
      ]

      const mNamesShort = [
        'Jan',
        'Feb',
        'Márc',
        'Ápr',
        'Máj',
        'Jún',
        'Júl',
        'Aug',
        'Szept',
        'Okt',
        'Nov',
        'Dec'
      ]

      if (startMonth !== endMonth && startMonth !== 12) {
        this.title = `${mNamesShort[startMonth - 1]} - ${mNamesShort[startMonth]}, ${yr}`
      } else {
        this.title = `${mNames[startMonth - 1]}, ${yr}`
      }
    },
    setToday () {
      this.focus = ''
    },
    showReservation (reservation) {
      this.reservationDialog = true
      this.selectedReservation = reservation
    },
    getDuration ({ start, end }) {
      return `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`
    },
    getStatusColor (status) {
      switch (status) {
        case 'Függőben':
          return '#ffbc2b'
        case 'Elfogadva':
          return '#2ff778'
        case 'Elutasítva':
          return '#cc3310'
        case 'Lemondva':
          return '#6e0101'
        default:
          return 'black'
      }
    },
    getFulfilledColor (reservation) {
      if (reservation.status === 'Elfogadva') {
        if (reservation.fulfilled) {
          return '#2ff778'
        } else {
          return '#cc3310'
        }
      }
      return '#cc3310'
    },
    getReservationTime (date, duration) {
      return `${date} - ${moment(date).add(parseInt(duration, 10), 'minutes').format('HH:mm')}`
    },
    setStatus (status, reason) {
      this.setReservationStatus({ status, reservation: this.selectedReservation, reason })
      this.reservationDialog = false
      this.rejectReasonDialog = false
    },
    setFulfilled (fulfilled) {
      this.setFulfilledStatus({ fulfilled, reservation: this.selectedReservation })
      this.reservationDialog = false
    },
    showRejectReasonDialog () {
      this.rejectReasonDialog = true
      this.reservationDialog = false
    },
    isRejectVisible (reservation) {
      if ((reservation.status === 'Elfogadva' || reservation.status === 'Függőben') && moment(reservation.date).isAfter(moment())) {
        return true
      }
      return false
    },
    isAcceptVisible (reservation) {
      if ((reservation.status === 'Elutasítva' || reservation.status === 'Függőben') && moment(reservation.date).isAfter(moment())) {
        return true
      }
      return false
    },
    isNotFulfilledVisible (reservation) {
      if (reservation.status === 'Elfogadva' && reservation.fulfilled && moment(reservation.date).isBefore(moment())) {
        return true
      }
      return false
    },
    isFulfilledVisible (reservation) {
      if (reservation.status === 'Elfogadva' && !reservation.fulfilled && moment(reservation.date).isBefore(moment())) {
        return true
      }
      return false
    },
    isItPast (reservation) {
      return moment(reservation.date).isBefore(moment())
    },
    showFulfilled (reservation) {
      if (reservation.status === 'Elfogadva') {
        if (reservation.fulfilled) {
          return 'Teljesült'
        } else {
          return 'Nem teljesült'
        }
      }
      return 'Nem teljesült'
    }
  }
}
</script>

<style lang="sass">
.calendar-wrapper
  height: 80vh
.v-event-timed-container
  margin-right: 0 !important
.reserve-label
  width: 100%
  height: 100%
  padding: 4px
  font-weight: bold
.v-calendar-daily_head-day-label
  font-size: 10px !important
.v-btn--fab.v-size--default
  height: 35px !important
  width: 35px !important
.today-button
  padding: 0
  margin-left: -16px !important
  height: 40px !important
  width: 45px !important
  min-width: 1px !important
  font-weight: 400 !important
.prev-next-button
  margin-left: 5px
  padding: 0
  min-width: 1px !important
  width: 35px !important
  height: 40px !important
.content
  display: flex
  .car
    margin-right: 16px
.primary-color
  color: var(--v-primary-base)
.reservation-dialog
  color: black
.v-current-time
  height: 2px
  background-color: #ea4335
  position: absolute
  left: -1px
  right: 0
  pointer-events: none
  .first::before
    content: ''
    position: absolute
    background-color: #ea4335
    width: 12px
    height: 12px
    border-radius: 50%
    margin-top: -5px
    margin-left: -6.5px
.cars-display
  display: flex
  justify-content: left
  align-items: center
</style>
