<template>
  <v-snackbar v-model="snackbar" :timeout="5000">
    {{ notification }}
    <template v-slot:action="{ attrs }">
      <v-btn
        color="primary"
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Bezárás
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  data: () => ({
    snackbar: false
  }),
  computed: {
    ...mapState(['notification'])
  },
  watch: {
    notification (val) {
      if (val) {
        this.snackbar = true
      }
    },
    snackbar (val) {
      if (!val) {
        setTimeout(() => this.setNotification(''), 150)
      }
    }
  },
  methods: {
    ...mapMutations(['setNotification'])
  }
}
</script>
