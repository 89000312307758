<template>
  <v-dialog v-model="show" width="500" @click:outside="close">
    <v-card>
      <v-card-title class="primary mb-3 card-title">Foglalás elutasítása</v-card-title>
      <v-card-text>
        <div class="reject-dialog">
          <v-textarea placeholder="Elutasítás indoklása (kötelező megadni)" v-model="reason" />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close">Mégse</v-btn>
        <v-btn :disabled="isDisabled" color="primary" @click="confirm">Elutasítás</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, default: false }
  },
  data: () => ({
    reason: ''
  }),
  watch: {
    show () {
      this.reason = ''
    }
  },
  computed: {
    isDisabled () {
      return this.reason === ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    confirm () {
      this.$emit('confirm', this.reason)
    }
  }
}
</script>
